.registrant-info-input {
  width: 300px;
  margin-left: 10px;
}

.registrant-info-input-wide {
  width: 300px;
  margin-left: 10px;
}

.registrant-info-field-container {
  margin-bottom: 10px;
}

span#registrant-info-error-state {
  font-size: 0.8em;
  color: red;
}
