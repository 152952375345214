button {
  margin-left: 5px;
}

.session-info-input {
  width: 300px;
}

div.margin-right-10 {
  margin-right: 10px;
}

span.session-info-input {
  margin-top: 8px;
}

.session-info-input-margin {
  margin-bottom: 10px;
}

div.session-slot {
  margin-top: 5px;
  margin-bottom: 5px;
}

div#session-info-session-fullness {
  margin-top: 10px;
  margin-bottom: 10px;
}

div#session-info-reservation-header {
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: underline;
  font-weight: bold;
  font-size: larger;
}

span#sesson-info-error-state {
  font-size: 0.8em;
  color: red;
}

button.session-slot-edit-button, button.session-slot-cancel-button {
  margin-bottom: 10px;
}
