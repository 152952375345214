.admin-view-session-header {
  font-size: medium;
  font-weight: bold;
  margin-top: 20px;
}

.admin-view-card-field-label {
  color: dimgray;
}

.admin-view-card-field-name {
  font-weight: bold;
}

.admin-view-run-header {
  font-weight: bold;
}

.admin-view-selectors > Button {
  margin-right: 20px;
}

div.MuiGrid-root > span {
  float: left;
}

Button {
  text-transform: none;
}

Button.sort-clear {
  margin-top: 10px;
  margin-bottom: 10px;
}

.admin-view-round-header {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: xx-large;
  font-weight: bold;
  width: 100%;
}

.admin-view-height-header {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: xx-large;
  font-weight: bold;
  width: 100%;
}

.admin-view-signup-table {
  width: 100%;
  border-collapse: collapse;
}

.admin-view-signup-table-cell {
  font-size: x-large;
  width: 90%;
}

.admin-view-signup-table-container {
  display: block;
  width: 95%;
}
