.config-view-event-field {
  width: 30%;
}

.config-view-event-max-students {
  width: 15%;
}

.config-view-event-paypal-clientid {
  width: 80%;
}

.config-view-event-service-fee {
  width: 30%;
}

.config-view-event-service-fee-basis {
  width: 40%;
}

.config-view-session-field-name {
  width: 35%;
}

.config-view-card {
  margin-top: 10px;
  border: 2px solid black;
  border-radius: 25px;
}

.config-view-content-wrapper {
   margin-left: 10px;
   margin-right: 10px;
   margin-bottom: 10px;
}

.config-view-edit-container {
  margin-top: 20px;
}

button.config-view-event-action-button {
  margin-left:5px;
}

div.config-view-button-wrapper {
  margin-top: 20px;
}

div.config-view-notice, div.config-view-contact-email {
  width: 90%;
}

span.config-view-session-header {
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}

span.config-view-event-details {
  margin-left: 10px;
  text-align: center;
  vertical-align: middle;
}

span.config-view-session-field-error {
  color: red;
  vertical-align: middle;
  margin-left: 10px;
  line-height: 40px;
}

button.config-view-new-event {
  margin-top: 10px;
}
